import { useLoginStore } from "~/stores/LoginStore";

/**
 * GTM用処理
 */
export const useGTM = () => {
  // GTM用ユーザーID登録の間隔。24時間ごとに登録する
  const GTM_USER_ID_INTERVAL = 1000 * 60 * 60 * 24;

  // ログインstore
  const loginStore = useLoginStore();

  // GTM用ユーザーID登録用タイマー
  let gtmIntevalId: any = null;

  // GTM用ユーザーID登録
  const setGtmUserId = async () => {
    if (await loginStore.setCookieForGTM()) {
      // GTMのuser_idをリセットするためリロードする
      reloadNuxtApp({
        ttl: 1000, // デフォルト 10000
      });
      return;
    }
    // 一定時間ごとにGTM用ユーザーIDを登録する
    gtmIntevalId = setInterval(() => {
      loginStore.setCookieForGTM();
    }, GTM_USER_ID_INTERVAL);
  };

  const onMounted = () => {
    // GTM用ユーザーID登録
    setGtmUserId();
  };

  const onUnmounted = () => {
    if (gtmIntevalId) {
      clearInterval(gtmIntevalId);
      gtmIntevalId = null;
    }
  };

  return {
    onMounted,
    onUnmounted,
  };
};
